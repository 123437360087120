import React from 'react'
import UploadForum from "../components/uploadForum";

function WriteForum() {
    return (
        <div>
            <UploadForum></UploadForum>
        </div>
    )
}

export default WriteForum
