import React from 'react';
import LoginComp from '../components/Login';
function Login() {
    return (
        <>
            <LoginComp></LoginComp>
        </>
    )
}

export default Login
