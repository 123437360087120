import React from 'react'
import SignupComp from'../components/Signup';
function Signup() {
    return (
        <div>
            <SignupComp></SignupComp>
        </div>
    )
}

export default Signup
