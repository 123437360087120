import React from 'react'
import ShowForum from '../components/ShowForum'

function AllForum() {
    return (
        <div>
            <ShowForum></ShowForum>
        </div>
    )
}

export default AllForum
