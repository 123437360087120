import React from 'react'
import ContactUs from '../components/ContactUs'

function Contactus() {
    
    return (
        <ContactUs></ContactUs>
    )
}

export default Contactus
