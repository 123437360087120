import React from 'react'
import About from '../components/About'

function AboutUs() {
    
    return (
        <About></About>
    )
}

export default AboutUs
