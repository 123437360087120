import React from 'react'
import UploadBlog from '../components/UploadBlog';

function Write() {
    return (
        <div>
           <UploadBlog></UploadBlog> 
        </div>
    )
}

export default Write
